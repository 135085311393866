<!--业务员列表-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-input placeholder="搜索姓名" v-model="select.search" size="small" style="width:300px;margin-right:auto" @change="curr=1;getList()" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff" @click="curr=1;getList()"></el-button>
                </el-input>
            </div>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="id" label="ID" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="姓名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="price" label="区域" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.area_code_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="账号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="area_user_count" label="区域内客户数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="area_member_count" label="开通会员数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="area_member_balance" label="订单提成金额" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="jumpMember(scope.row)">{{scope.row.balance_all}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="withdraw" label="已提现金额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="balance" label="目前提成金额" show-overflow-tooltip></el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            select:{},
            list: [], //商品列表

            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        //商品列表
        getList: function () {
            this.http.post('/admin.admin/indexStaff',{
                curr: this.curr,
                row: this.row,
                role_id:3,
                ...this.select
            }).then(response => {
                this.list = response.list;
                this.curr = response.curr;
                this.pages = response.pages;
                this.count = response.count;
            })
        },
        jumpMember(row){
            this.$router.push({
                path:'/order',
                query:{
                    admin_id:row.id
                }
            })
        },
        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getList();
        }
    }
}
</script>